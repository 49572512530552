/* Load in your font */
@import url("https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Asap&family=Montserrat&display=swap"); */

body {
  margin: 0;
  font-family: Asap, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  touch-action: none;
}

div {
  font-size: 12px;
}

p {
  font-size: 12px;
}

span {
  font-size: 12px;
}

div > span,
div > p,
div > div {
  font-size: 12px;
}

.jsoneditor-menu > button.jsoneditor-sort {
  display: none;
}
