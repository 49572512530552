.react-tel-input .country-list .country:hover {
  background-color: inherit;
  text-decoration: underline;
}

.react-tel-input .country-list .country.highlight {
  background-color: inherit;
  text-decoration: underline;
}

.react-tel-input .country-list .search {
  position: sticky;
  top: 0;
  background-color: inherit;
  padding: 10px 0 6px 10px;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: inherit;
  border-radius: 3px 0 0 0;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: inherit;
}
