/* 

.rjsf {
  height: inherit;
}

.rjsf > div > fieldset {
  overflow-y: auto;
}

.form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 2;
  width: auto;
}

.field-object {
  height: inherit;
}

fieldset .field-object {
  height: min-content;
}

fieldset {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

fieldset fieldset {
  border: none;
  margin: 0;
  padding: 0;
  height: min-content;
}

.field-boolean {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: flex-end;
}

.form-control:active {
  border: 1px solid #026dad;
}

.control-label {
  display: none;
}

fieldset fieldset legend {
  display: none;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.inputTextUpperCase > input {
  text-transform: uppercase;
} 

*/

.rjsf > div > .btn-info {
  display: none;
}

/* COMMON STYLES */

#tsparticles {
  width: 100%;
  height: 100%;
}

td .MuiIconButton-root {
  padding: 5px;
}

.MuiToolbar-regular {
  min-height: 40px !important ;
}

#m--table--filter--row > .MuiTableCell-root {
  padding: 3px;
}

#choose-layout .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 5px;
}

#choose_view {
  padding-left: 5px;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #0a0335;
  box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

@-webkit-keyframes moveUp {
  0% {
    transform: translateY(0) rotate(180deg);
  }
  33% {
    transform: translateY(+2px) rotate(180deg);
  }
  66% {
    transform: translateY(+1px) rotate(180deg);
  }
  100% {
    transform: translateY(+2px) rotate(180deg);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0) rotate(180deg);
  }
  33% {
    transform: translateY(+2px) rotate(180deg);
  }
  66% {
    transform: translateY(+1px) rotate(180deg);
  }
  100% {
    transform: translateY(+2px) rotate(180deg);
  }
}

@-webkit-keyframes moveDown {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(-2px);
  }
  66% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(-2px);
  }
}

@keyframes moveDown {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(-2px);
  }
  66% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(-2px);
  }
}

/* * {
  outline: 3px solid #f00 !important;
  opacity: 1 !important;
  visibility: visible !important;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}